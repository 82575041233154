import { useEffect, useState } from 'react'

export default function useProductDescription(productId) {
    const [descriptionMd, setDescriptionMd] = useState('Loading...')

    useEffect(() => {
        async function getMd() {
            setDescriptionMd(await (await fetch(path)).text())
        }

        if (!productId) {
            setDescriptionMd(null)
            return
        }

        let path = null
        try {
            path = require(`../content/products/descriptions/${productId}.md`)
        } catch (e) {
            console.error(`Product description for ${productId} not found!`)
        }

        if (!path) {
            setDescriptionMd(null)
            return
        }

        getMd()
    }, [productId, setDescriptionMd])

    return descriptionMd
}
