import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import useProducts from '../../hooks/useProducts'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Carousel from '../shared/Carousel'
import getImageUrl from '../../helpers/getImageUrl'

const galleryMaxSize = 5

function HomeCarouselPrevArrow({ className, onClick }) {
    return (
        <div
            className={`${className} carousel-arrow home`}
            style={{
                left: 10,
            }}
            onClick={onClick}
        />
    )
}

function HomeCaroselNextArrow({ className, onClick }) {
    return (
        <div
            className={`${className} carousel-arrow home`}
            style={{
                right: 10,
            }}
            onClick={onClick}
        />
    )
}

export default function ShowcasedProducts() {
    const products = useProducts()
    const { width } = useWindowDimensions()
    const isExpired = useCallback((product) => {
        return Date.parse(product.imageTag.expiry) < new Date()
    }, [])

    const size = React.useMemo(
        () =>
            products.length > galleryMaxSize ? galleryMaxSize : products.length,
        [products]
    )

    const renderImage = useCallback(
        (product) => {
            return (
                <div className="image">
                    <Link to={`/products/${product.id}`}>
                        {product.imageTag && !isExpired(product) && (
                            <div id="image-tag">
                                <span
                                    style={{
                                        boxShadow: `0 0 90px -20px ${product.imageTag.color}`,
                                        border: `2px solid ${product.imageTag.color}`,
                                    }}
                                >
                                    <h2
                                        style={{
                                            color: product.imageTag.color,
                                        }}
                                    >
                                        {product.imageTag.text}
                                    </h2>
                                </span>
                            </div>
                        )}
                        <img
                            src={getImageUrl(product.images[0].src)}
                            alt={product.images[0].alt}
                        />
                    </Link>
                </div>
            )
        },
        [isExpired]
    )

    return (
        <Carousel
            config={{
                autoplaySpeed: 8000,
                autoplay: false,
                pauseOnHover: true,
                prevArrow: <HomeCarouselPrevArrow />,
                nextArrow: <HomeCaroselNextArrow />,
            }}
        >
            {products.slice(0, size).map((product, i) => (
                <div key={i}>
                    <div className="content">
                        <div className="description">
                            <header>
                                <span className="new-tag">NEW!</span>

                                <Link to={`/products/${product.id}`}>
                                    <h3>{product.title}</h3>
                                </Link>
                            </header>
                            {width <= 1680 && renderImage(product)}
                            <p>{product.highlight}</p>
                            <Link to="/products" className="button alt">
                                Shop Now
                            </Link>
                        </div>
                        {width > 1680 && renderImage(product)}
                    </div>
                </div>
            ))}
        </Carousel>
    )
}
