import React from 'react'
import { useParams } from 'react-router-dom'
import '../../css/projects.css'
import useProject from '../../hooks/useProject'
import ReactMarkdown from 'react-markdown'
import useProjectDescription from '../../hooks/useProjectDescription'
import Carousel from '../shared/Carousel'
import getImageUrl from '../../helpers/getImageUrl'

export default function Project() {
    let { projectId } = useParams()
    let project = useProject(projectId)
    let descriptionMD = useProjectDescription(projectId)
    return (
        <>
            <div className="project-title">
                <h1>{project.title}</h1>
            </div>
            <div className="full-project-view box-shadow">
                <div className="full-project-img-ctn">
                    <Carousel>
                        {project.images.map((image, i) => (
                            <div key={i}>
                                <img
                                    src={getImageUrl(image.src)}
                                    alt={image.alt}
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
                {descriptionMD && (
                    <div className="full-project-desc-ctn">
                        <ReactMarkdown className="prod-desc">
                            {descriptionMD}
                        </ReactMarkdown>
                    </div>
                )}
            </div>
            <div className="project-actions">
                <a
                    href={project.buyUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="button special"
                >
                    Buy
                </a>
            </div>
        </>
    )
}
