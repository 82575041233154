import React, { useEffect } from 'react'
import useScrollToTop from '../../hooks/useScrollToTop'
export default function Contact() {
    useScrollToTop()

    useEffect(() => {
        document.title = `Contact Us | 3D Design Technology`
        return () => {
            document.title = '3D Design Technology'
        }
    }, [])

    return (
        <section id="main">
            <div>
                <p>
                    For general inquiries and technical questions:
                    <br />
                    <br />
                    Please send us an email with your questions and we will get
                    back to you quickly with how 3D Design Technology LLC can
                    assist!
                </p>
            </div>
        </section>
    )
}
