import Slider from 'react-slick'
import React, { useEffect, useRef } from 'react'

function DefaultCarouselPrevArrow({ className, onClick }) {
    return (
        <div
            className={`${className} carousel-arrow`}
            style={{
                left: 0,
            }}
            onClick={onClick}
        />
    )
}

function DefaultCaroselNextArrow({ className, onClick }) {
    return (
        <div
            className={`${className} carousel-arrow`}
            style={{
                right: 0,
            }}
            onClick={onClick}
        />
    )
}

export default function Carousel({ children, config, pause }) {
    const sliderRef = useRef(null)

    useEffect(() => {
        if (!sliderRef.current) {
            return
        }

        if (pause) {
            sliderRef.current.slickPause()
        } else {
            sliderRef.current.slickPlay()
        }
    }, [sliderRef, pause])

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnDotsHover: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <DefaultCarouselPrevArrow />,
        nextArrow: <DefaultCaroselNextArrow />,
        ...config,
    }

    return (
        <Slider {...settings} ref={sliderRef}>
            {children}
        </Slider>
    )
}
