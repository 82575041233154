import React, { useEffect } from 'react'
import useScrollToTop from '../../hooks/useScrollToTop'
export default function About() {
    useEffect(() => {
        document.title = `About | 3D Design Technology`
        return () => {
            document.title = '3D Design Technology'
        }
    }, [])
    useScrollToTop()
    return (
        <section id="banner">
            <div className="inner">
                <div className="boxed">
                    <p>
                        3D Design Technology LLC specializes in using 3D
                        Technologies in the design and manufacture of new or
                        replacement parts or components. Whether the original
                        part is no longer available, a custom modification is
                        required to an existing part, or a new design needs to
                        be tested prior to investing in full scale production,
                        we are here to help. 3D Design Technology LLC is an
                        technology led and driven organization leveraging 10+
                        years of Aerospace Engineering experience.
                    </p>
                    <p>
                        Our focus is on quality and customer satisfaction
                        through the use of sound technological principles and
                        expertise. We have experience working on parts and
                        components from the automotive, aerospace, power-sports,
                        marine, and industrial equipment industries, as well as
                        everyday household items. As an technology based
                        organization, we take pride in solving problems big or
                        small. Whether the task is complicated or
                        straightforward, a one off trial, or something bigger we
                        will work with you to determine the best way to solve
                        your problem and make your idea a reality.
                    </p>
                </div>
            </div>
        </section>
    )
}
