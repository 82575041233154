import React, { useEffect } from 'react'
import useScrollToTop from '../../hooks/useScrollToTop'

export default function Services() {
    useScrollToTop()

    useEffect(() => {
        document.title = `Services | 3D Design Technology`
        return () => {
            document.title = '3D Design Technology'
        }
    }, [])

    return (
        <>
            <section id="main">
                <div className="inner">
                    <h3>Reverse Engineering*</h3>
                    <ul>
                        <li>Dimensional</li>
                        <li>Functional</li>
                        <li>Material</li>
                        <li>Design Modification</li>
                        <li>3D CAD Models</li>
                        <li>2D Drawings</li>
                    </ul>
                    <hr />

                    <h3>New Design Development</h3>
                    <ul>
                        <li>3D CAD Models</li>
                        <li>2D Drawings</li>
                        <li>Material Selection</li>
                        <li>Manufacturing Process Selection</li>
                    </ul>
                    <hr />

                    <h3>3D Printing / Additive Manufacturing</h3>
                    <ul>
                        <li>Prototyping</li>
                        <li>Design Function Verification</li>
                        <li>Low Volume End Use Parts</li>
                        <li>Tooling / Fixtures / Jigs</li>
                        <li>Prismatic and Organic Shapes</li>
                    </ul>
                    <hr />

                    <h3>Silicone Molding / Plastic Casting</h3>
                    <ul>
                        <li>
                            Low Volume Production / Reproduction / Duplication
                        </li>
                        <li>Prismatic and Organic Shapes</li>
                        <li>
                            Range of Material Properties from Rubber-like to
                            ABS-like
                        </li>
                    </ul>
                    <hr />

                    <h3>Conventional Machining</h3>
                    <h4>3 Axis Manual Milling</h4>
                    <ul>
                        <li>Prototyping</li>
                        <li>Design Function Verification</li>
                        <li>Low Volume End Use Parts</li>
                        <li>Tooling / Fixtures / Jigs</li>
                    </ul>
                    <h4>Water Jet Cutting</h4>
                    <ul>
                        <li> Prototyping</li>
                        <li> Design Function Verification</li>
                        <li> Low Volume End Use Parts</li>
                    </ul>
                    <hr />

                    <h3>Fabrication</h3>
                    <h4>Wire Bending</h4>
                    <ul>
                        <li>Small Diameter Manual Solid Wire Bending</li>
                        <li>Prototyping</li>
                        <li>Design Function Verification</li>
                        <li>Low Volume End Use Parts</li>
                        <li>Custom Tooling Design and Manufacture</li>
                    </ul>
                    <h4>Sheet Metal</h4>
                    <ul>
                        <li>Small Component Sheet Metal Bending and Cutting</li>
                        <li>Prototyping</li>
                        <li>Design Function Verification</li>
                        <li>Low Volume End Use Parts</li>
                        <li>Custom Tooling Design and Manufacture</li>
                    </ul>
                    <h4>Brazing</h4>
                    <ul>
                        <li>Torch Brazing of Similar and Dissimilar Metals</li>
                    </ul>
                    <h4>Welding</h4>
                    <ul>
                        <li>
                            GMAW/FCAW - Mig and FLux Core Arc Welding, Including
                            Aluminum
                        </li>
                        <li>DC SMAW - Stick Welding</li>
                        <li>DC GTAW - Tig Welding</li>
                    </ul>
                    <h4>Ultrasonic Cleaning</h4>
                    <ul>
                        <li>
                            Heat Controlled Alkaline Cleaning Solution with Rust
                            Inhibitor
                        </li>
                        <li>
                            Clean Plastic or Metal Parts Between Operations and
                            Prior to Shipment
                        </li>
                    </ul>
                    <h4>Wet Blasting</h4>
                    <ul>
                        <li>
                            120 Mesh and Smaller Grit Blasting with Rust
                            Inhibitor Additive
                        </li>
                        <li>Post Processing Plastic or Metal Parts</li>
                        <li>Deburr Machined Metal Parts</li>
                        <li>Remove Plastic Casting Flashing</li>
                        <li>Post Process 3D Printed Parts</li>
                        <li>
                            Remove Paint or Other Surface Contamination Prior to
                            Reverse Engineering Process
                        </li>
                    </ul>
                    <h4>Vibratory Polishing</h4>
                    <ul>
                        <li>Post processing Plastic or Metal Parts</li>
                        <li>Deburr Machined Metal Parts</li>
                        <li>Remove Plastic Casting Flashing</li>
                        <li>Post Process 3D Printed Parts</li>
                        <li>Improve Surface Finish and Shine of Parts</li>
                    </ul>
                    <h4>Chemical Black Oxide Processing</h4>
                    <ul>
                        <li>
                            Chemical Conversion Process to Blacken Steel and
                            Iron Parts
                        </li>
                        <li>Parts Sealed after Oxide Layer Depostion</li>
                        <li>Prevents Rust</li>
                        <li>
                            Good Intermediate Process to Protect Parts During
                            Shipping or Storeage
                        </li>
                        <li>
                            Great Surface Treatment to Protect End Use Parts
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}
