import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import getImageUrl from '../../helpers/getImageUrl'

export default function ProductCard({ product }) {
    let { url } = useRouteMatch()
    return (
        <div className="product-card box-shadow">
            <div className="prod-img-cnt">
                <Link to={`${url}/${product.id}`}>
                    <img
                        src={getImageUrl(product.images[0].src)}
                        alt={product.images[0].alt}
                    />
                </Link>
            </div>
            <div className="prod-text-cnt">
                <div className="prod-title">
                    <Link to={`${url}/${product.id}`}>
                        <h5>{product.title}</h5>
                    </Link>
                </div>
                <div className="prod-summary">{product.summary}</div>
                <a
                    className="button alt"
                    href={product.buyUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Buy it now
                </a>
            </div>
        </div>
    )
}
