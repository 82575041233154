import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import '../../css/projects.css'
import Project from './Project.jsx'
import useProjectCategories from '../../hooks/useProjectCategories'
import ProjectCategory from './ProjectCategory'

export default function Projects() {
    const projectCategories = useProjectCategories()
    const { path } = useRouteMatch()
    return (
        <section id="projects">
            <Switch>
                <Route exact path={path}>
                    {projectCategories.map((projectCategory, i) => (
                        <ProjectCategory
                            key={i}
                            projectCategory={projectCategory}
                        />
                    ))}
                </Route>
                <Route path={`${path}/:projectId`}>
                    <div className="project-wrapper">
                        <Project />
                    </div>
                </Route>
            </Switch>
        </section>
    )
}
