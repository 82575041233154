import React, { useEffect } from 'react'
import ProductCard from './ProductCard'
import useProducts from '../../hooks/useProducts'

export default function ProductCategory({ productCategory }) {
    const products = useProducts(productCategory.id)

    useEffect(() => {
        document.title = `Products | 3D Design Technology`
        return () => {
            document.title = '3D Design Technology'
        }
    }, [])

    return (
        <div className="product-category">
            <h2>{productCategory.title}</h2>
            <div className="products-list">
                {products.map((product, i) => (
                    <ProductCard key={i} product={product} />
                ))}
            </div>
        </div>
    )
}
