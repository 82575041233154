import React from 'react'
import { init, send } from 'emailjs-com'

const userId = 'user_jVijhQ4bhtCp5rMl8A87X'
const serviceId = 'service_hpqc37p'
const templateId = 'template_qij2mgp'
const year = new Date().getFullYear()

export default function Footer() {
    React.useEffect(() => {
        init(userId)
    }, [])

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [subject, setSubject] = React.useState('')
    const [company, setCompany] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [messageSent, setMessageSent] = React.useState(false)

    return (
        <section id="footer">
            <div className="inner">
                <header>
                    <h2>Get in Touch</h2>
                </header>
                <form
                    onSubmit={async (e) => {
                        e.preventDefault()
                        setMessageSent(false)

                        var templateParams = {
                            from_name: name,
                            to_name: '3D Design Technology',
                            email: email,
                            subject: subject,
                            company: company,
                            message: message,
                        }
                        await send(serviceId, templateId, templateParams)

                        setName('')
                        setEmail('')
                        setSubject('')
                        setCompany('')
                        setMessage('')
                        setMessageSent(true)
                    }}
                >
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            required
                            onChange={(e) => setName(e.currentTarget.value)}
                        />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                    </div>
                    <div className="field half first">
                        <label htmlFor="email">Company</label>
                        <input
                            type="text"
                            name="company"
                            id="company"
                            value={company}
                            onChange={(e) => setCompany(e.currentTarget.value)}
                        />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Subject</label>
                        <input
                            type="text"
                            name="subject"
                            id="subject"
                            value={subject}
                            required
                            onChange={(e) => setSubject(e.currentTarget.value)}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea
                            name="message"
                            id="message"
                            rows="6"
                            value={message}
                            required
                            onChange={(e) => setMessage(e.currentTarget.value)}
                        ></textarea>
                    </div>
                    <ul className="actions">
                        <li>
                            <input
                                type="submit"
                                value="Send Message"
                                className="alt"
                            />
                            {messageSent && (
                                <label className="message-sent-conf">
                                    Your request has been sent!
                                </label>
                            )}
                        </li>
                    </ul>
                </form>
                <span className="disclaimer">
                    * 3D Design Technology LLC is not a Licenced Professional Engineering Organization nor does 3D Design Technology LLC offer Professional Engineering services.  3D Design Technology LLC cannot offer Reverse Engineering Services, or any other services, on any project or product that involves safeguarding life, health, or property.  
                </span>
                <div className="copyright">
                    {`3D DESIGN TECHNOLOGY LLC, COPYRIGHT © 2018-${year}`}
                </div>
            </div>
        </section>
    )
}
