import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import getImageUrl from '../../helpers/getImageUrl'

export default function ProjectCard({ project }) {
    let { url } = useRouteMatch()
    return (
        <div className="project-card box-shadow">
            <div className="proj-img-cnt">
                <img
                    src={getImageUrl(project.images[0].src)}
                    alt={project.images[0].alt}
                />
            </div>
            <div className="proj-text-cnt">
                <div className="proj-title">
                    <Link to={`${url}/${project.id}`}>
                        <h5>{project.title}</h5>
                    </Link>
                </div>
                <div className="proj-summary">{project.summary}</div>
            </div>
        </div>
    )
}
