import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import logo from '../../assets/images/web-logo-trans.png'
import Popover from './Popover'
import useCookie from '../../hooks/useCookie'
//import useAnnouncements from '../../hooks/useAnnouncements'
import useProducts from '../../hooks/useProducts'

export default function Header() {
    const productMenuRef = useRef(null)
    const [burgerMenuOpen, setBurgerMenuOpen] = React.useState(false)
    const announcement = null //useAnnouncements()
    const products = useProducts()
    const [showProductSubMenu, setShowProductSubMenu] = useState(false)
    const { cookie: displayedPopover, updateCookie } = useCookie(
        'displayedPopover',
        false
    )
    let { pathname } = useLocation()

    const getActiveClass = useCallback(
        (link) => {
            if (link === pathname) {
                return 'active'
            }

            return pathname.indexOf(link) > -1 && link !== '/' ? 'active' : ''
        },
        [pathname]
    )

    useEffect(() => {
        const productMenuEl = productMenuRef.current
        if (!productMenuEl) {
            return
        }

        const onEnter = function (e) {
            setShowProductSubMenu(true)
        }

        const onLeave = function (e) {
            setShowProductSubMenu(false)
        }

        productMenuEl.addEventListener('mouseenter', onEnter)
        productMenuEl.addEventListener('mouseleave', onLeave)
        return () => {
            productMenuEl.removeEventListener('mouseenter', onEnter)
            productMenuEl.removeEventListener('mouseleave', onLeave)
        }
    }, [])

    return (
        <>
            {announcement && !displayedPopover && (
                <Popover
                    announcement={announcement}
                    onClick={() => updateCookie(true, 1)}
                />
            )}
            <header id="header">
                <a href="/" className="logo">
                    <img src={logo} alt="3D Design Technology LLC" />
                </a>
                <div className="inner">
                    <nav id="nav">
                        <Link className={getActiveClass('/')} to="/">
                            Home
                        </Link>
                        <Link
                            className={getActiveClass('services')}
                            to="/services"
                        >
                            Services
                        </Link>
                        <span
                            className="product-menu-cont"
                            ref={productMenuRef}
                        >
                            <Link
                                className={getActiveClass('products')}
                                to="/products"
                            >
                                Products
                            </Link>
                            {showProductSubMenu && (
                                <div className="products-sub-menu">
                                    {products.map((product, i) => (
                                        <Link
                                            className={getActiveClass(
                                                product.id
                                            )}
                                            key={i}
                                            to={`/products/${product.id}`}
                                        >
                                            {product.title}
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </span>
                        {/* <Link aboutclassName={getMenuActiveClass('/projects')} to="/projects/">Projects</Link> */}
                        <Link className={getActiveClass('about')} to="/about">
                            About us
                        </Link>
                    </nav>
                    <span id="burger">
                        <Menu
                            isOpen={burgerMenuOpen}
                            right
                            width="230"
                            onOpen={() => setBurgerMenuOpen(true)}
                            onClose={() => setBurgerMenuOpen(false)}
                        >
                            <a
                                id="home"
                                className={`menu-item ${getActiveClass('/')}`}
                                href="/"
                                onClick={() => setBurgerMenuOpen(false)}
                            >
                                Home
                            </a>
                            <a
                                id="services"
                                className={`menu-item ${getActiveClass(
                                    'services'
                                )}`}
                                href="/services"
                                onClick={() => setBurgerMenuOpen(false)}
                            >
                                Services
                            </a>

                            <a
                                to="products"
                                className={`menu-item ${getActiveClass(
                                    'products'
                                )}`}
                                href="/products"
                                onClick={() => setBurgerMenuOpen(false)}
                            >
                                Products
                            </a>
                            <a
                                id="about"
                                className={`menu-item ${getActiveClass(
                                    'about'
                                )}`}
                                href="/about"
                                onClick={() => setBurgerMenuOpen(false)}
                            >
                                About us
                            </a>
                        </Menu>
                    </span>
                </div>
            </header>
        </>
    )
}
