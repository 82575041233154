import React from 'react'
import getImageUrl from '../../helpers/getImageUrl'

export default function Overlay({ image, onClose }) {
    return (
        <>
            <div onClick={onClose} className="overlay" />
            <img
                onClick={onClose}
                className="overlay-img"
                src={getImageUrl(image.src)}
                alt={image.alt}
            />
        </>
    )
}
