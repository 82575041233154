import React from 'react'
import ProjectCard from './ProjectCard'
import useProjects from '../../hooks/useProjects'

export default function ProjectCategory({ projectCategory }) {
    const projects = useProjects(projectCategory.id)

    return (
        <div className="project-category">
            <h2>{projectCategory.title}</h2>
            <div className="projects-list">
                {projects.map((project, i) => (
                    <ProjectCard key={i} project={project} />
                ))}
            </div>
        </div>
    )
}
