import React, { useCallback, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/home/Home'
import Header from './components/shared/Header'
import Footer from './components/shared/Footer'
import Services from './components/services/Services'
import About from './components/about/About'
import Contact from './components/contact/Contact'
import Products from './components/products/Products'
import Projects from './components/projects/Projects'
import useProducts from './hooks/useProducts'

function App() {
    const products = useProducts()

    const injectProductSchema = useCallback((product) => {
        var el = document.createElement('script')
        el.type = 'application/ld+json'
        el.text = JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Product',
            name: product.title,
            image: `${window.location.origin}/${encodeURI(
                product.images[0].src
            )}`,
            description: product.highlight,
            brand: {
                '@type': 'Brand',
                name: '3D Design Technology',
            },
            mpn: product.mpn,
            sku: product.sku,
            offers: {
                '@type': 'Offer',
                availability: 'InStock',
                price: product.price,
                priceCurrency: 'USD',
                manufacturer: '3D Design Technology LLC',
                url: `${window.location.origin}/products/${product.id}`,
                priceValidUntil: '1/1/2300',
            },
        })
        document.querySelector('head').appendChild(el)
    }, [])

    useEffect(() => {
        products.forEach(injectProductSchema)
    }, [injectProductSchema, products])

    return (
        <Router>
            <div>
                <Header />
                <Switch>
                    <Route path="/services">
                        <Services />
                    </Route>
                    <Route path="/products">
                        <Products />
                    </Route>
                    <Route path="/projects">
                        <Projects />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
                <Footer />
            </div>
        </Router>
    )
}

export default App
