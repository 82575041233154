import React from 'react'
import useScrollToTop from '../../hooks/useScrollToTop'
import ShowcasedProducts from './ShowcasedProducts'

export default function Home() {
    useScrollToTop()

    return (
        <>
            <section id="banner">
                <div className="inner">
                    <h1>
                        Providing accessible technology expertise to make
                        ambitions, creations, and solutions for everyone a
                        reality
                    </h1>
                    <ul className="actions">
                        <li>
                            <a
                                href="\"
                                className="button alt"
                                onClick={(e) => {
                                    e.preventDefault()
                                    document.getElementById('name').focus()
                                    window.scrollTo(
                                        0,
                                        document.body.scrollHeight
                                    )
                                }}
                            >
                                Get Started
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="examples">
                <div id="product-title">
                    <h1>Products</h1>
                </div>
                <article>
                    <ShowcasedProducts />
                </article>
                {/* <article className="alt">
                    <Carousel
                        config={{
                            autoplaySpeed: 8000,
                            autoplay: true,
                            pauseOnHover: true,
                            prevArrow: <HomeCarouselPrevArrow />,
                            nextArrow: <HomeCaroselNextArrow />,
                        }}
                    >
                        {showcasedProjects}
                    </Carousel>
                </article> */}
            </section>
        </>
    )
}
