import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../css/products.css'
import useProduct from '../../hooks/useProduct'
import ReactMarkdown from 'react-markdown'
import useProductDescription from '../../hooks/useProductDescription'
import Carousel from '../shared/Carousel'
import getImageUrl from '../../helpers/getImageUrl'
import useScrollToTop from '../../hooks/useScrollToTop'
import Overlay from '../shared/Overlay'

function ProductCarouselPrevArrow({ className, onClick }) {
    return (
        <div
            className={`${className} prod-carousel-arrow carousel-arrow`}
            onClick={onClick}
        />
    )
}

function ProductCaroselNextArrow({ className, onClick }) {
    return (
        <div
            className={`${className} prod-carousel-arrow carousel-arrow`}
            onClick={onClick}
        />
    )
}

export default function Product() {
    useScrollToTop()

    const [imageSelected, setImageSelected] = useState(null)
    let { productId } = useParams()
    let product = useProduct(productId)
    let descriptionMD = useProductDescription(productId)

    useEffect(() => {
        document.title = `${product.title} | 3D Design Technology`

        const metaTag = document.querySelector('meta[name="description"]')
        const content = metaTag.getAttribute('content')
        metaTag.setAttribute('content', product.summary)

        return () => {
            document.title = '3D Design Technology'

            metaTag.setAttribute('content', content)
        }
    }, [product])

    return (
        <>
            <div className="product-title">
                <h1>{product.title}</h1>
            </div>
            <div className="full-product-img-ctn">
                {imageSelected && (
                    <Overlay
                        image={imageSelected}
                        onClose={() => setImageSelected(null)}
                    />
                )}
                <Carousel
                    config={{
                        prevArrow: <ProductCarouselPrevArrow />,
                        nextArrow: <ProductCaroselNextArrow />,
                    }}
                    pause={!!imageSelected}
                >
                    {product.images.map((image, i) => (
                        <div className="prod-img" key={i}>
                            <img
                                onClick={() => {
                                    setImageSelected(image)
                                }}
                                src={getImageUrl(image.src)}
                                alt={image.alt}
                            />
                        </div>
                    ))}
                </Carousel>
            </div>
            {descriptionMD && (
                <div className="full-product-desc-ctn">
                    <ReactMarkdown className="prod-desc">
                        {descriptionMD}
                    </ReactMarkdown>
                </div>
            )}
            <div className="product-actions">
                <a
                    href={product.buyUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="button special"
                >
                    Buy
                </a>
            </div>
        </>
    )
}
