import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import '../../css/products.css'
import Product from './Product'
import ProductCategory from './ProductCategory'
import useProductCategories from '../../hooks/useProductCategories'
import useScrollToTop from '../../hooks/useScrollToTop'

export default function Products() {
    useScrollToTop()

    const productCategories = useProductCategories()
    const { path } = useRouteMatch()
    return (
        <section id="products">
            <Switch>
                <Route exact path={path}>
                    {productCategories.map((productCategory, i) => (
                        <ProductCategory
                            key={i}
                            productCategory={productCategory}
                        />
                    ))}
                </Route>
                <Route path={`${path}/:productId`}>
                    <div className="product-wrapper">
                        <Product />
                    </div>
                </Route>
            </Switch>
        </section>
    )
}
