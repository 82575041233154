import { useEffect, useState } from 'react'

export default function useProjectDescription(projectId) {
    const [descriptionMd, setDescriptionMd] = useState('Loading...')

    useEffect(() => {
        async function getMd() {
            setDescriptionMd(await (await fetch(path)).text())
        }

        if (!projectId) {
            setDescriptionMd(null)
            return
        }

        let path = null
        try {
            path = require(`../content/projects/descriptions/${projectId}.md`)
        } catch (e) {
            console.error(`Project description for ${projectId} not found!`)
        }

        if (!path) {
            setDescriptionMd(null)
            return
        }

        getMd()
    }, [projectId, setDescriptionMd])

    return descriptionMd
}
